/**
 * Vuex-actions
 */
 export default {
  // 保存 会话
  updateAllConversation(context,data) {
    context.commit('updateAllConversation',data);
  }

 
}