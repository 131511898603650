/**
 * Vuex-mutation
 */
import formatTime from '../util/check.js'

export default {
  // 会话列表
  updateAllConversation(state,list){
    console.log(list,'会话列表')
    let num = 0;
    for (let i = 0; i < list.length; i++) {
      if (list[i].lastMessage && (typeof list[i].lastMessage.lastTime === 'number')) {
        let date = new Date(list[i].lastMessage.lastTime * 1000)
        list[i].lastMessage._lastTime = formatTime.formatTime(date)
      }
      list[i]['show'] = false;

      num += list[i].unreadCount;
    }
    state.allConversation = list;
    state.count = num;
    console.log(num, '未读消息数');
  }
}