/**
 * 登录注册模块接口列表
 */
 import axios from '@/api/http.js'; // 导入http中创建的axios实例

 const login = {
    // 发送验证码
    sendmessage(params) {
      return axios.post(`api/sendmessage`, params);
    },
    // 文件上传
    upload(params) {
      return axios.post(`common/upload`, params);
    },
    // 登录 注册
    login(params) {
      return axios.post(`api/login`, params);
    },
    // 用户详情
    userInfo(params) {
      return axios.post(`api/lsUserInfo`,params);
    },
    // 企业审核详情
    qiyeinfo(params) {
      return axios.post(`api/qiyeinfo`,params);
    },
    // 提交企业信息
    qiye(params) {
      return axios.post(`api/qiye`,params);
    },
    // 修改企业信息
    qiyeedit(params) {
      return axios.post(`api/qiyeedit`,params);
    },

 }

 export default login;