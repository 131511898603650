/**
 * 模块接口列表
 */
import axios from '@/api/http.js'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块

const main = {
    // 首页==================================================
    // 去咨询 提问
    addorder(params){
        return axios.post(`api/addorder`, params);
    },
    // 轮播图
    lsLunbolist(){
        return axios.post(`api/lsLunbolist`);
    },

    // 法律知识列表(热门领域),（问题类型）
    lsLawKnowledgelist(params) {
        return axios.post(`api/lsLawKnowledgelist`, params);
    },
    // 金牌律师列表
    lsLawyerindex(params){
        return axios.post(`api/lsLawyerindex`, params);
    },
    //  精选服务 咨询方式
    lsFuwulist(params){
        return axios.post(`api/lsFuwulist`, params);
    },
    // 最新咨询 问答列表
    wendalist(params){
        return axios.post(`api/wendalist`, params);
    },
    // 最新评价
    zuixinorderlist(params){
        return axios.post(`api/zuixinorderlist`, params);
    },
    //  热门资讯列表
    lsInformationlist(params){
        return axios.post(`api/lsInformationlist`, params);
    },
    // 点赞 取消点赞咨讯
    likeinformation(params){
        return axios.post(`api/likeinformation`, params);
    },
     // 收藏取消收藏咨讯
    collectinformation(params){
        return axios.post(`api/collectinformation`, params);
    },
    // 获取服务详情 
    lsFuwuinfo(params){
        return axios.post(`api/lsFuwuinfo`, params);
    },
    // 推荐法律知识
    lsLawKnowledgetuijian(params){
        return axios.post(`api/lsLawKnowledgetuijian`, params);
    },

    // ============法律咨询 ================================== 
    // 咨询详情
    wendainfo(params){
        return axios.post(`api/wendainfo`, params);
    },

    // 法律文书  标签
    lsFuwulabel(){
        return axios.post(`api/lsFuwulabel`);
    },



    // 找律师===================================================
    // 获取律师详情
    lsLawyerinfo(params){
        return axios.post(`api/lsLawyerinfo`, params);
    },
    // 关注取消关注律师
    lsLawyerlike(params){
        return axios.post(`api/lsLawyerlike`, params);
    },
    // 关注取消法律知识
    likelsLawKnowledge(params){
        return axios.post(`api/likelsLawKnowledge`, params);
    },

    // ========资讯=======
    // 资讯详情
    lsInformationinfo(params){
        return axios.post(`api/lsInformationinfo`, params);
    },

    // 系统通知
    lsUserMsglist(params){
        return axios.post(`api/lsUserMsglist`, params);
    },

    // ======个人中心 =======
    // 用户详情
    userInfo(params){
        return axios.post(`api/lsUserInfo`, params);
    },
    // 企业审核详情
    qiyeinfo(params){
        return axios.post(`api/qiyeinfo`, params);
    },
    // 修改用户信息
    lsUseredit(params){
        return axios.post(`api/lsUseredit`, params);
    },
    //  我的钱包
    lsUsermoneylog(params){
        return axios.post(`api/lsUsermoneylog`, params);
    },
    //  提现
    addlsTixian(params){
        return axios.post(`api/addlsTixian`, params);
    },
    // 银行卡列表
    lsUserBanklist(params){
        return axios.post(`api/lsUserBanklist`, params);
    },
    // 新增银行卡
    lsUserBankadd(params){
        return axios.post(`api/lsUserBankadd`, params);
    },
    // 修改银行卡
    lsUserBankedit(params){
        return axios.post(`api/lsUserBankedit`, params);
    },
    // 删除银行卡
    lsUserBankremove(params){
        return axios.post(`api/lsUserBankremove`, params);
    },
    // 会员卡信息
    lsUserMembers(){
        return axios.post(`api/lsUserMembers`);
    },
    // 用户会员支付
    userMembersorderpay(params){
        return axios.post(`api/userMembers/orderpay`,params);
    },
    // 用户订单列表
    userOrderList(params){
        return axios.post(`api/userOrderList`,params);
    },
    // 订单支付
    orderpay(params){
        return axios.post(`api/orderpay`,params);
    },
    // 订单评价
    pinglunorder(params){
        return axios.post(`api/pinglunorder`,params);
    },
    // 订单售后
    shouhouorder(params){
        return axios.post(`api/shouhouorder`,params);
    },
    // 订单售后  撤销
    cexiaoshouhouorder(params){
        return axios.post(`api/cexiaoshouhouorder`,params);
    },

    // 我的关注，用户关注的律师列表
    lsUserLikeLawyer(params){
        return axios.post(`api/lsUserLikeLawyer`,params);
    },
    // 我的收藏，用户收藏的资讯
    lsUsercollect(params){
        return axios.post(`api/lsUsercollect`,params);
    },
    // 根据用户律师手机号获取订单 （咨询聊天）
    orderinfophone(params){
        return axios.post(`api/orderinfophone`,params);
    },
    // 订单聊天记录用户手机号律师手机号 （发送消息）
    msgorderphone(params){
        return axios.post(`api/msgorderphone`,params);
    },

    // 问题类型
    lsProblemTypelist(params){
        return axios.post(`api/lsProblemTypelist`,params);
    },

    // 问题列表
    lsProblemlist(params){
        return axios.post(`api/lsProblemlist`,params);
    },
    // 问题详情
    lsProbleminfo(params){
        return axios.post(`api/lsProbleminfo`,params);
    },
    // 协议内容
    lsXieyiinfo(params){
        return axios.post(`api/lsXieyiinfo`,params);
    },

     // 订单详情
     orderInfo(params){
        return axios.post(`api/orderInfo `,params);
    },
    //  会员订单详情
    userMembersorderInfo(params){
        return axios.post(`api/userMembers/orderInfo `,params);
    },

}

export default main;