import Vue from 'vue'
import App from './App.vue'
// 全部引入elementUI
// import ElementUI from 'element-ui' // 引入elementUI
// import 'element-ui/lib/theme-chalk/index.css'
// 按需引入elementUI
import { Message,Button,Input, Dialog, Form, FormItem, Upload, Menu,Submenu,MenuItem,MenuItemGroup,Select,Option,OptionGroup,Radio,RadioGroup,RadioButton,Table,TableColumn,Pagination,DatePicker,Collapse ,CollapseItem,Popover,Image,Checkbox, Cascader ,Rate, Tabs,TabPane, Breadcrumb, BreadcrumbItem, Carousel, CarouselItem,MessageBox, Tooltip,  Badge, } from 'element-ui'
import router from './router' // 路由
import api from './api' // 导入api接口
import VueLazyLoad from 'vue-lazyload' // 引入懒加载
import VueCookie from 'vue-cookie' // 引入cookie
import store from './store' // 引入vuex
import chatMsg from "./tim/chatMsg.js"


// mock开关
// const mock = true;
// // 运行时根据条件动态加载
// if (mock) {
//   require('./mock/api')
// }

// 路由守卫
router.beforeEach((to, from, next) => {
  // 404 页面
  if (to.matched.length === 0) { 
    // from.name ? next({
    //   name: from.name
    // }) : next('/404'); 
    next('/404'); 
  } else {
    next(); //如果匹配到正确跳转
  }
})

// 全部引入elementUI
// Vue.use(ElementUI)

// 按需引入elementUI

Vue.use(Button);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(DatePicker);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popover);
Vue.use(Image);
Vue.use(Checkbox);
Vue.use(Cascader);
Vue.use(Rate);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tooltip);
Vue.use(Badge);
 

Vue.use(VueLazyLoad, {
  // loading: '/imgs/loading-svg/loading-bars.svg'
})
Vue.use(VueCookie);

Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;

Vue.prototype.$api = api // 将api挂载到vue的原型上
Vue.config.productionTip = false  // 关闭生产模式下给出的提示



// 即时通信 start=====================================================================================
import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';
const tim = TIM.create({
	SDKAppID: 1400562003
}); // SDK 实例通常用 tim 表示
// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
// 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
tim.registerPlugin({
	'tim-upload-plugin': TIMUploadPlugin
});
tim.on(TIM.EVENT.SDK_READY,sdk_ready);
tim.on(TIM.EVENT.NET_STATE_CHANGE, net_state_change);
tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, updataMsg);
tim.on(TIM.EVENT.MESSAGE_RECEIVED, messageReceived);
tim.on(TIM.EVENT.ERROR, errormsg);
tim.on(TIM.EVENT.KICKED_OUT, kicked_out);
tim.on(TIM.EVENT.SDK_NOT_READY, function(event) {
 console.log('SDK_NOT_READY')
 console.log(event)
});
function messageReceived(event) {
	console.log(event,'有新消息')
	//新消息
	uni.vibrateShort({
		success: function() {
			console.log('success');
		}
	});
}
function sdk_ready(event){
	console.log('eventsdk_ready')
	console.log(event)
}
function updataMsg(event) {
	// 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
	console.log(event.data,'会话更新');
	store.commit('updateAllConversation', event.data);
}
function errormsg(event) {
	console.log(event.message,'error...');
	this.$message.error('请重新登录');
	localStorage.clear();
}
function net_state_change(event) {
	console.log('net_state_change...')
	console.log(event)
	if (event.data.state == "disconnected") {
    // this.$message.error('当前网络不可用');
	} else if (event.data.state == "connecting") {
    // this.$message.error('当前网络不稳定');
	} 
}
function kicked_out(event) {
	console.log('kicked_out')
	console.log(event)
  this.$message.error('您已被踢下线');
  localStorage.clear();
}
Vue.prototype.TIM = TIM;
Vue.prototype.$tim = tim;
Vue.prototype.$chatMsg = chatMsg

// 即时通信 end=============================================================================================



new Vue({
  render: h => h(App), // h函数会生成一个VNode节点，render函数得到这个VNode节点之后，返回给Vue.js的mount函数渲染成真实DOM节点，并挂载到根节点上。
  router,
  store,
}).$mount('#app')
