const commen = {}


commen.emojiList = [
	[{
		"url": "100.gif",
		alt: "[微笑]"
	}, {
		"url": "101.gif",
		alt: "[伤心]"
	}, {
		"url": "102.gif",
		alt: "[美女]"
	}, {
		"url": "103.gif",
		alt: "[发呆]"
	}, {
		"url": "104.gif",
		alt: "[墨镜]"
	}, {
		"url": "105.gif",
		alt: "[哭]"
	}, {
		"url": "106.gif",
		alt: "[羞]"
	}, {
		"url": "107.gif",
		alt: "[哑]"
	}, {
		"url": "108.gif",
		alt: "[睡]"
	}, {
		"url": "109.gif",
		alt: "[哭]"
	}, {
		"url": "110.gif",
		alt: "[囧]"
	}, {
		"url": "111.gif",
		alt: "[怒]"
	}, {
		"url": "112.gif",
		alt: "[调皮]"
	}, {
		"url": "113.gif",
		alt: "[笑]"
	}, {
		"url": "114.gif",
		alt: "[惊讶]"
	}, {
		"url": "115.gif",
		alt: "[难过]"
	}, {
		"url": "116.gif",
		alt: "[酷]"
	}, {
		"url": "117.gif",
		alt: "[汗]"
	}, {
		"url": "118.gif",
		alt: "[抓狂]"
	}, {
		"url": "119.gif",
		alt: "[吐]"
	}, {
		"url": "120.gif",
		alt: "[笑]"
	}, {
		"url": "121.gif",
		alt: "[快乐]"
	}, {
		"url": "122.gif",
		alt: "[奇]"
	}, {
		"url": "123.gif",
		alt: "[傲]"
	}],
	[{
		"url": "124.gif",
		alt: "[饿]"
	}, {
		"url": "125.gif",
		alt: "[累]"
	}, {
		"url": "126.gif",
		alt: "[吓]"
	}, {
		"url": "127.gif",
		alt: "[汗]"
	}, {
		"url": "128.gif",
		alt: "[高兴]"
	}, {
		"url": "129.gif",
		alt: "[闲]"
	}, {
		"url": "130.gif",
		alt: "[努力]"
	}, {
		"url": "131.gif",
		alt: "[骂]"
	}, {
		"url": "132.gif",
		alt: "[疑问]"
	}, {
		"url": "133.gif",
		alt: "[秘密]"
	}, {
		"url": "134.gif",
		alt: "[乱]"
	}, {
		"url": "135.gif",
		alt: "[疯]"
	}, {
		"url": "136.gif",
		alt: "[哀]"
	}, {
		"url": "137.gif",
		alt: "[鬼]"
	}, {
		"url": "138.gif",
		alt: "[打击]"
	}, {
		"url": "139.gif",
		alt: "[bye]"
	}, {
		"url": "140.gif",
		alt: "[汗]"
	}, {
		"url": "141.gif",
		alt: "[抠]"
	}, {
		"url": "142.gif",
		alt: "[鼓掌]"
	}, {
		"url": "143.gif",
		alt: "[糟糕]"
	}, {
		"url": "144.gif",
		alt: "[恶搞]"
	}, {
		"url": "145.gif",
		alt: "[什么]"
	}, {
		"url": "146.gif",
		alt: "[什么]"
	}, {
		"url": "147.gif",
		alt: "[累]"
	}],
	[{
		"url": "148.gif",
		alt: "[看]"
	}, {
		"url": "149.gif",
		alt: "[难过]"
	}, {
		"url": "150.gif",
		alt: "[难过]"
	}, {
		"url": "151.gif",
		alt: "[坏]"
	}, {
		"url": "152.gif",
		alt: "[亲]"
	}, {
		"url": "153.gif",
		alt: "[吓]"
	}, {
		"url": "154.gif",
		alt: "[可怜]"
	}, {
		"url": "155.gif",
		alt: "[刀]"
	}, {
		"url": "156.gif",
		alt: "[水果]"
	}, {
		"url": "157.gif",
		alt: "[酒]"
	}, {
		"url": "158.gif",
		alt: "[篮球]"
	}, {
		"url": "159.gif",
		alt: "[乒乓]"
	}, {
		"url": "160.gif",
		alt: "[咖啡]"
	}, {
		"url": "161.gif",
		alt: "[美食]"
	}, {
		"url": "162.gif",
		alt: "[动物]"
	}, {
		"url": "163.gif",
		alt: "[鲜花]"
	}, {
		"url": "164.gif",
		alt: "[枯]"
	}, {
		"url": "165.gif",
		alt: "[唇]"
	}, {
		"url": "166.gif",
		alt: "[爱]"
	}, {
		"url": "167.gif",
		alt: "[分手]"
	}, {
		"url": "168.gif",
		alt: "[生日]"
	}, {
		"url": "169.gif",
		alt: "[电]"
	}, {
		"url": "170.gif",
		alt: "[炸弹]"
	}, {
		"url": "171.gif",
		alt: "[刀子]"
	}],
	[{
		"url": "172.gif",
		alt: "[足球]"
	}, {
		"url": "173.gif",
		alt: "[瓢虫]"
	}, {
		"url": "174.gif",
		alt: "[翔]"
	}, {
		"url": "175.gif",
		alt: "[月亮]"
	}, {
		"url": "176.gif",
		alt: "[太阳]"
	}, {
		"url": "177.gif",
		alt: "[礼物]"
	}, {
		"url": "178.gif",
		alt: "[抱抱]"
	}, {
		"url": "179.gif",
		alt: "[拇指]"
	}, {
		"url": "180.gif",
		alt: "[贬低]"
	}, {
		"url": "181.gif",
		alt: "[握手]"
	}, {
		"url": "182.gif",
		alt: "[剪刀手]"
	}, {
		"url": "183.gif",
		alt: "[抱拳]"
	}, {
		"url": "184.gif",
		alt: "[勾引]"
	}, {
		"url": "185.gif",
		alt: "[拳头]"
	}, {
		"url": "186.gif",
		alt: "[小拇指]"
	}, {
		"url": "187.gif",
		alt: "[拇指八]"
	}, {
		"url": "188.gif",
		alt: "[食指]"
	}, {
		"url": "189.gif",
		alt: "[ok]"
	}, {
		"url": "190.gif",
		alt: "[情侣]"
	}, {
		"url": "191.gif",
		alt: "[爱心]"
	}, {
		"url": "192.gif",
		alt: "[蹦哒]"
	}, {
		"url": "193.gif",
		alt: "[颤抖]"
	}, {
		"url": "194.gif",
		alt: "[怄气]"
	}, {
		"url": "195.gif",
		alt: "[跳舞]"
	}],
	[{
		"url": "196.gif",
		alt: "[发呆]"
	}, {
		"url": "197.gif",
		alt: "[背着]"
	}, {
		"url": "198.gif",
		alt: "[伸手]"
	}, {
		"url": "199.gif",
		alt: "[耍帅]"
	}, {
		"url": "200.png",
		alt: "[微笑]"
	}, {
		"url": "201.png",
		alt: "[生病]"
	}, {
		"url": "202.png",
		alt: "[哭泣]"
	}, {
		"url": "203.png",
		alt: "[吐舌]"
	}, {
		"url": "204.png",
		alt: "[迷糊]"
	}, {
		"url": "205.png",
		alt: "[瞪眼]"
	}, {
		"url": "206.png",
		alt: "[恐怖]"
	}, {
		"url": "207.png",
		alt: "[忧愁]"
	}, {
		"url": "208.png",
		alt: "[眨眉]"
	}, {
		"url": "209.png",
		alt: "[闭眼]"
	}, {
		"url": "210.png",
		alt: "[鄙视]"
	}, {
		"url": "211.png",
		alt: "[阴暗]"
	}, {
		"url": "212.png",
		alt: "[小鬼]"
	}, {
		"url": "213.png",
		alt: "[礼物]"
	}, {
		"url": "214.png",
		alt: "[拜佛]"
	}, {
		"url": "215.png",
		alt: "[力量]"
	}, {
		"url": "216.png",
		alt: "[金钱]"
	}, {
		"url": "217.png",
		alt: "[蛋糕]"
	}, {
		"url": "218.png",
		alt: "[彩带]"
	}, {
		"url": "219.png",
		alt: "[礼物]"
	}, ]
]

/**@dateTimeFliter 转换格林日期时间格式为常用日期格式
 * @time[必填] 						Date  		格林日期格式
 * @part[可选,默认:0]				Number      选择返回日期时间部分  列:0:返回所有 1:只返回日期  2:只返回时间
 * @dateComplete[可选,默认:true] 	Boolean 	日期位数不足是否添0补齐:true:补齐,false:不补齐
 * @timeComplete[可选,默认:true] 	Boolean 	时间位数不足是否添0补齐:true:补齐,false:不补齐
 * @dateConnector[可选,默认:-] 		String 		年月日连接符  例: - : /
 * @timeConnector[可选,默认::] 		String 		时间连接符   例: - : /
 * @hour12[可选,默认:false]          Boolean     是否返回12小时制时间   例: true:返回12小时制时间   false:返回24小时制时间
 * @return   '2019-11-25 15:05:54'  String    返回示例
 * **/
commen.dateTimeFliter = function(time, part = 0, dateComplete = true, timeComplete = true, dateConnector = '-',
	timeConnector = ':', hour12 = false) {
	let year = time.getFullYear()
	let month = time.getMonth() + 1
	let day = time.getDate()
	let hour = time.getHours()
	let minute = time.getMinutes()
	let second = time.getSeconds()
	let dateStr = ''
	let timeStr = ''
	//转换日期
	if (dateComplete) { //添0补齐
		if (month < 10) {
			month = '0' + month
		}
		if (day < 10) {
			day = '0' + day
		}
	}
	dateStr = year + dateConnector + month + dateConnector + day
	//转换时间
	//修改小时制
	if (hour12) {
		if (hour > 12) {
			hour = hour - 12
			if (timeComplete) {
				if (hour < 10) {
					hour = '下午 ' + '0' + hour
				} else {
					hour = '下午 ' + hour
				}
			}
		} else {
			if (timeComplete) {
				if (hour < 10) {
					hour = '上午 ' + '0' + hour
				} else {
					hour = '上午 ' + hour
				}
			}
		}
	}
	//判断分钟与秒
	if (timeComplete) { //添0补齐
		if (minute < 10) {
			minute = '0' + minute
		}
		if (second < 10) {
			second = '0' + second
		}
	}
	timeStr = hour + timeConnector + minute + timeConnector + second
	//合成输出值
	if (part == 0) {
		return dateStr + ' ' + timeStr
	} else if (part == 1) {
		return dateStr
	} else if (part == 2) {
		return timeStr
	}
	return '传参有误'
}



export default commen
