/**
 * 路由封装
 * 路由懒加载方式一：
 *    1. component: resolve => require(['../pages/Index.vue'],resolve)  // 路由懒加载
 * 路由懒加载方式二：
 *    1. 需要添加 syntax-dynamic-import (opens new window)插件，才能使 Babel 可以正确地解析语法,`cnpm install --save-dev @babel/plugin-syntax-dynamic-import`
 *    2. component: () => import('../pages/Cart.vue'), // 路由懒加载
 */
// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'
// 导入页面组件
// import Login from '../pages/Login'
import Home from '../pages/Home'
// import Index from '../pages/Index'
// import Product from '../pages/Product'
// import Detail from '../pages/Detail'
// import Cart from '../pages/Cart'
// import Order from '../pages/Order'
// import OrderConfirm from '../pages/OrderConfirm'
// import OrderList from '../pages/OrderList'
// import OrderPay from '../pages/OrderPay'
// import Alipay from '../pages/Alipay'

// 加载路由插件
Vue.use(Router)
// 导出路由
export default new Router({
  // hash模式下，浏览器地址不规整,带有#
  // history模式下，浏览器地址规整，但需要后台支持
  mode:'hash',
  // 路由规则
  routes: [
    // 首页
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: '/index', // 重定向
      // 子路由
      children: [
        {
          path: '/index',
          name: 'index',
          component: () => import('../pages/Index.vue'),
          meta:{
            requireAuth: false // 是否需要 token显示
          }
        }
      ]
    },
    // 用户协议和隐私协议
    {
      path: '/xieyi',
      name: 'xieyi',
      component: () => import('../pages/xieyi/Index.vue'), 
      meta:{
        requireAuth: false 
      },
      children: [
        {
          path: '/yonghu',
          name: 'yonghu',
          component: () => import('../pages/xieyi/yonghu.vue'),
          meta:{
            requireAuth: false 
          }
        },
        {
          path: '/yinsi',
          name: 'yinsi',
          component: () => import('../pages/xieyi/yinsi.vue'),
          meta:{
            requireAuth: false 
          }
        }
      ]
    },
    // 发起咨询和热门领域详情
    {
      path: '/homepage',
      name: 'homepage',
      component: () => import('../pages/homepage/Index.vue'), 
      meta:{
        requireAuth: false 
      },
      children: [
        {
          path: '/lydetails',
          name: 'lydetails',
          component: () => import('../pages/homepage/lydetails.vue'),
          meta:{
            requireAuth: false 
          }
        },
        {
          path: '/toask',
          name: 'toask',
          component: () => import('../pages/homepage/toask.vue'),
          meta:{
            requireAuth: false 
          }
        }
      ]
    },
    // 法律咨询 flzixunIndex
    {
      path: '/flzixun',
      name: 'flzixun',
      component: () => import('../pages/flzixun/Index.vue'), 
      meta:{
        requireAuth: false 
      },
      children: [
        {
          path: '/falvzixun',
          name: 'falvzixun',
          component: () => import('../pages/flzixun/shouye.vue'),
          meta:{
            requireAuth: false 
          }
        },
        {
          path: '/typedetails',
          name: 'typedetails',
          component: () => import('../pages/flzixun/typedetails.vue'),
          meta:{
            requireAuth: false 
          }
        },
        {
          path: '/wendetails',
          name: 'wendetails',
          component: () => import('../pages/flzixun/wendetails.vue'),
          meta:{
            requireAuth: false 
          }
        }
      ]
    },
      // 法律服务 flfuwuIndex falvfuwu   法律文书 falvwenshu
      {
        path: '/flfuwu',
        name: 'flfuwu',
        component: () => import('../pages/flfuwu/Index.vue'), 
        meta:{
          requireAuth: false 
        },
        children: [
          {
            path: '/falvwenshu',
            name: 'falvwenshu',
            component: () => import('../pages/flfuwu/shouye.vue'),
            meta:{
              requireAuth: false 
            }
          }
        ]
      },
      // 找律师 findIndex
      {
        path: '/find',
        name: 'find',
        component: () => import('../pages/find/Index.vue'), 
        meta:{
          requireAuth: false 
        },
        children: [
          {
            path: '/zhaolvshi',
            name: 'zhaolvshi',
            component: () => import('../pages/find/shouye.vue'),
            meta:{
              requireAuth: false 
            }
          },
          // {
          //   path: '/lvlist',
          //   name: 'lvlist',
          //   component: () => import('../pages/find/lvlist.vue'),
          //   meta:{
          //     requireAuth: false 
          //   }
          // },
          {
            path: '/lvdetails',
            name: 'lvdetails',
            component: () => import('../pages/find/lvdetails.vue'),
            meta:{
              requireAuth: false 
            }
          },
          {
            path: '/wenls',
            name: 'wenls',
            component: () => import('../pages/find/wenls.vue'),
            meta:{
              requireAuth: false 
            }
          }
        ]
      },
      // 律师列表
      {
        path: '/lvlist',
        name:'lvlist',
        component: () => import('../pages/lvlist.vue'),
        meta:{
            requireAuth: false 
        }
      }, 
        // 问法 askIndex
        {
          path: '/ask',
          name: 'ask',
          component: () => import('../pages/ask/Index.vue'), 
          meta:{
            requireAuth: false 
          },
          children: [
            {
              path: '/wenfa',
              name: 'wenfa',
              component: () => import('../pages/ask/shouye.vue'),
              meta:{
                requireAuth: false 
              }
            },
            {
              path: '/askdetails',
              name: 'askdetails',
              component: () => import('../pages/ask/askdetails.vue'),
              meta:{
                requireAuth: false 
              }
            },
          ]
        },
        // 热点资讯 infoIndex
        {
        path: '/info',
        name: 'info',
        component: () => import('../pages/info/Index.vue'), 
        meta:{
          requireAuth: false 
        },
        children: [
          {
            path: '/redianzixun',
            name: 'redianzixun',
            component: () => import('../pages/info/shouye.vue'),
            meta:{
              requireAuth: false 
            }
          },
          {
            path: '/infolist',
            name: 'infolist',
            component: () => import('../pages/info/infolist.vue'),
            meta:{
              requireAuth: false 
            }
          },
          {
            path: '/infodetails',
            name: 'infodetails',
            component: () => import('../pages/info/infodetails.vue'),
            meta:{
              requireAuth: false 
            }
          }
        ]
      },

      // 侵权监测
      {
        path: '/tort',
        name: 'tort',
        component: () => import('../pages/monitor/Index.vue'), 
        meta:{
          requireAuth: false 
        },
        children: [
          {
            path: '/monitor',
            name: 'monitor',
            component: () => import('../pages/monitor/shouye.vue'),
            meta:{
              requireAuth: false 
            }
          },
        ]
      },

        // 消息通知
        {
          path: '/message',
          name: 'message',
          component: () => import('../pages/message/Index.vue'), 
          meta:{
            requireAuth: true 
          },
          children: [
            {
              path: '/msg',
              name: 'msg',
              component: () => import('../pages/message/msg.vue'),
              meta:{
                requireAuth: true 
              }
            },
            {
              path: '/chat',
              name: 'chat',
              component: () => import('../pages/message/chat.vue'),
              meta:{
                requireAuth: true 
              }
            },
            {
              path: '/helping',
              name: 'helping',
              component: () => import('../pages/message/helping.vue'),
              meta:{
                requireAuth: true 
              }
            },
            {
              path: '/helpingDetail',
              name: 'helpingDetail',
              component: () => import('../pages/message/helping-detail.vue'),
              meta:{
                requireAuth: true 
              }
            }
          ]
        },
      // 个人中心 userinfo wallet
      {
        path: '/center',
        name: 'center',
        component: () => import('../pages/center/Index.vue'), 
        meta:{
          requireAuth: true 
        },
        children: [
          {
            path: '/gerenziliao',
            name: 'gerenziliao',
            component: () => import('../pages/center/userinfo.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/wodeqianbao',
            name: 'wodeqianbao',
            component: () => import('../pages/center/wallet.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/tixian',
            name: 'tixian',
            component: () => import('../pages/center/tixian.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/zixun',
            name: 'zixun',
            component: () => import('../pages/center/zixun.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/pingjia',
            name: 'pingjia',
            component: () => import('../pages/center/pingjia.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/shouhou',
            name: 'shouhou',
            component: () => import('../pages/center/shouhou.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/follow',
            name: 'follow',
            component: () => import('../pages/center/follow.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/authentify',
            name: 'authentify',
            component: () => import('../pages/center/authentify.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/member',
            name: 'member',
            component: () => import('../pages/center/member.vue'),
            meta:{
              requireAuth: true 
            }
          },
          {
            path: '/card',
            name: 'card',
            component: () => import('../pages/center/card.vue'),
            meta:{
              requireAuth: true 
            }
          }
        ]
      },
   
  
    {
      path: '/404',
      name:'404',
      component: () => import('../pages/404.vue'),
    },
  ]
})